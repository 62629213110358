<template>
    <div>
        <!-- <select id="my_select" @change="onSelectChange">
            <option v-for="option in options" :value="option.id" v-bind:key="option.id" :selected="option.id == preselectedId">    
                {{ $t(option.isoCode3) }}
            </option>
        </select> -->

        <select id="my_select" @change="onSelectChange">
            <option v-for="option in options" :value="option.id" v-bind:key="option.id" :selected="option.id == preselectedId">               
                {{ $t(option.name) }}
              <!-- {{ $t(option.isoCode3) }} -->
            </option>
        </select>

        <!-- <v-select :options="optionsTranslated" @input="onSelectChange" :value="selectedOption">
            <template v-slot:option="option">     
                {{option.label}}
            </template>
        </v-select> -->
    </div>
</template>

<script>
import $ from 'jquery'
// import vselect from 'vue-select'

export default {
    data(){
        return  {
            selectedOption: ""
        }
    },
    name:"select-picker", 
    props: {
        options:{
            type: Array,
            required: true
        },
        callback: {
            Type: Function
        },
        preselectedId: {
            Type:Number
        }
    },
    components:{
        //  'v-select':vselect
    },
    computed:{
        optionsTranslated(){
            var optTranslated = [];
            for(var i = 0; i < this.options.length; i++)
            {
                optTranslated.push(this.$t(this.options[i].isoCode3));
            }
            console.log(optTranslated);
            return optTranslated;
        }
    },
    mounted(){
        var my_options = $("#my_select option");
        var selected = $("#my_select").val();


        my_options.sort(function(o1, o2) {
        var t1 = o1.text.toLowerCase(), t2 = o2.text.toLowerCase();

        return t1 > t2 ? 1 : t1 < t2 ? -1 : 0;
        });
        // .sort(function(a,b) {
        //     if (a.text > b.text) return 1;
        //     if (a.text < b.text) return -1;
        //     return 0
        // })

        $("#my_select").empty().append( my_options );
        $("#my_select").val(selected);
    },
    methods:{
        onSelectChange(element){
            this.selected = element;
            this.selectedOption = element;
            if(this.callback != undefined)
            {
                if(element != undefined)
                {
                    this.callback(element.target.value);
                }
                else
                {
                    this.callback(null);
                }
            }
        }
    }
}
</script>

<style>

select{
    width:100%;
    height:45px;
    background-color:white;
    border-radius:5px;
    border:0.5px solid lightgray;
    padding-left:10px;
}

.vueselect-wrapper .vs--searchable .vs__dropdown-toggle {
    width:100%;
    margin-right:0px;
}

select:focus{
    border:0.5px solid;
    border-radius:5px;
    border-color: var(--focus-color);
    box-shadow: 0 1px 1px var(--focus-color) inset, 0 0 8px var(--focus-color);
    outline: 0 none;
}
</style>
<template>
    <div class="shopCards startPageBackgroundImage">

        <modal ref="shopModal" >
            <template v-slot:header>                
                <h1>{{selectedShop.name}}</h1>
            </template>

            <template v-slot:body>
                <div class="col-12">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="padding-left:0;" >
                        <div style="text-align:start; padding-bottom:10px;" v-if="showShopExtraDetails" v-html="selectedShop.custom6"> </div>
                        <div style="text-align:start" v-if="shopShopModalAddress" v-html="selectedShop.addresses[0].formattedAddress"></div>
                        <img class="imageModal" :src="selectedShop.image" />
                    </div> 
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="padding:0;">
                        <order-options-page></order-options-page>  
                    </div>     
                </div>
            </template>

            <template v-slot:footer>
                <div class="d-flex align-items-center justify-content-between">
                </div>
            </template>
        </modal>
        
        <loading :active.sync="isLoading" 
            :can-cancel="true"        
            :is-full-page="true"
            loader='bars'></loading>

        <!-- wrapper to prevent showing an empty shop-card when the element is still loading -->
        <div v-if="showChoice" class="shopCards">
            <div class="shopPageTitle">
                    {{ $t('subTitle.chooseShop') }}
            </div>   
            <shop-carousel v-if="showCarousel" :shops="shops" :callbackSelect="selectShop"> </shop-carousel>
            <div class="col-12 shopCardWrapper" style="padding: 2% 10% 15% 10%; align-items: center;justify-content: center;">
                <shop-card v-for="shop in shops" v-bind:key="shop.id" :id="shop.id" :addresses="shop.addresses" :image="shop.image" :name="shop.name" :url="shop.custom10" :code="shop.custom2" :callback="selectShop"></shop-card>
            </div>
        </div>

        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
    </div>
</template>

<script>
import Modal from '../sub-components/Modal.vue'
import ShopCard from '../main-components/ShopCard.vue'
import VueLoading from 'vue-loading-overlay'
import Carousel from '../sub-components/ShopCarousel.vue'
import OrderOptionsPage from '../page-components/OrderOptionsPage.vue'
import $ from 'jquery'
import Utils from "@/utils/utils";
import {UserTypes} from "@/constants/constants";

export default {
    data() {
        return {
            isLoading: false,
            shops: [],
            showChoice: false,
            selectedShop: null,
            shopIdPreselected: 0,
            jwtString: "",
            showCarousel: true,
            shopShopModalAddress: false,
            showShopExtraDetails: false,
            deltaY: 0
        }
    },
    computed:{
        config() {
            return window.config;
        }
    },
    beforeMount(){
        if((this.jwtString != "" && this.jwtString != undefined && this.jwtString != null) || (this.code != "" && this.code != undefined && this.code != null))
        {
            this.isLoading = true;
        }

        if(window.preselectedShopCode !== undefined)
        {
            this.shopIdPreselected = window.preselectedShopCode;
        }
        else
        {
            this.shopIdPreselected = this.shopId;
            window.preselectedShopCode = this.shopId;
        }   
        
        if(this.jwt != undefined){
            window.jwt = this.jwt;
        }
        if (this.code != undefined) {
            window.ssoCode = this.code;
        }
    },
    created() {
      // Access query parameters
      const { id_token, s, code } = this.$route.query;

      // Initialize component state or make API calls
      this.jwt = id_token || null;  // Default to null if missing
      this.shopId = s || null;       // Default to null if missing
      this.code = code || null;      // Default to null if missing

      // You can now perform any operations with these parameters
      if (this.jwt && this.code) {
        this.checkJWT();
      }
    },
    async mounted() {

      this.showChoice = false;
      var config = window.config;

      if ((this.jwtString != "" && this.jwtString != undefined && this.jwtString != null) || (this.code != "" && this.code != undefined && this.code != null)) {
        this.isLoading = true;
      }

      console.log("Tableckecout " + config.TABLE_CHECKOUT.enabled);
      console.log("TableMode " + window.TableMode);
      console.log(this.code);
      console.log(this.jwt);

      if (window.config.COOKIES_ENABLED) {
        if (this.$cookies.isKey('TableMode')) {
          const tableModeValue = this.$cookies.get('TableMode');
          const tableId = this.$cookies.get('tableId');
          const shopId = this.$cookies.get('shopId');
          const isTableMode = tableModeValue === true || tableModeValue.toString().toUpperCase() === "TRUE";

          console.log(shopId);
          console.log(tableId);

          if (isTableMode) {
            window.TableMode = true;
            console.log("tableMode " + window.TableMode);

            // Merge table mode configurations into the global config
            Object.assign(window.config, window.configTablemode);

            if (shopId != undefined && window.immediate) {
              let path = "/QRLanding?s=" + shopId + "&i=" + window.immediate;
              await this.$router.push({path: path});
            } else if (shopId != undefined && tableId != undefined) {
              if (this.jwt != undefined || this.code != undefined) {
                let path = "/QRLanding?s=" + shopId + "&t=" + tableId;
                if (this.jwt != undefined) {
                  path = path + "&id_token=" + this.jwt;
                } else if (this.code != undefined) {
                  path = path + "&code=" + this.code;
                }
                window.jwt = undefined;
                window.ssoCode = undefined;
                await this.$router.push({path: path});
              } else {
                //nur mit shop und tableid senden
                let path = "/QRLanding?s=" + shopId + "&t=" + tableId;
                await this.$router.push({path: path});
              }
            } else {
              let path = "/QRLanding?h=true";
              await this.$router.push({path: path});
            }

            if (shopId == undefined || tableId == null) {
              window.location.href = config.TABLE_CHECKOUT.homepage;
            } else {
              await this.$router.push({path: "/Menu"});
            }
            return;
          }
        }
      }

      await this.checkJWT();
      await this.loadShops();

      this.shopIdPreselected = this.shopId;

      setTimeout(async () => {
        if (window.screen.width > 700 && config.SHOW_GLOBAL_HEADER_BANNER_IMAGE == true) {
          $(".header").addClass("header-big");
          $(".content").addClass("header-big-content-margin");
          $("#content-wrapper").bind("wheel", function (event) {
            this.handleWheelContent(event);
          });
        } else {
          $(".content").addClass("header-small-content-margin");
        }

        window.promotionBarcode = "";

        window.shopObj = null;
        window.timeString = null;
      }, 5);
    },
    props: {
        callback: {
            Type: Function
        },
        jwt: {
            Type:String,
            Default: "",
        },
        shopId:{
            Type: Number,
            Default: 0
        },
        code: {
            Type:String,
            Default: "",
        },
        validationType: {
          Type:String,
          Default: "",
        },
    },
    methods: {
        async getToken() {
          const result = await this.doGet('TCPLoginJ.action', {
            json: "true"
          });

          if (result.xsrfToken != undefined) {
            window.xtoken = atob(result.xsrfToken);
          }
        },
        waitOneSecond() {
          return new Promise((resolve) => {
          setTimeout(() => {
            resolve(); // Löst das Promise nach 1 Sekunde auf
          }, 1000);
        });
        },
        async checkJWT() {
          var config = window.config;
          this.showCarousel = config.SHOW_SHOP_CAROUSEL;
          this.shopShopModalAddress = config.SHOW_SHOP_DETAILS_ADDRESS;
          this.showShopExtraDetails = config.SHOW_SHOP_EXTRA_DETAILS;
          window.shopSelected = false;

          this.isLoading = true;
          this.jwtString = this.jwt;

            if ((this.jwtString != "" && this.jwtString != undefined && this.jwtString != null) || (this.code != "" && this.code != undefined && this.code != null)) {

              const result = await this.doPost('TCPLoginWithJWTJ.action', {
                jwt: !Utils.isEmpty(this.jwtString) ? this.jwtString : undefined,
                code: !Utils.isEmpty(this.code) ? this.code : undefined,
                shopCode: !Utils.isEmpty(this.shopId) ? this.shopId : undefined,
                validationType: !Utils.isEmpty(this.validationType) ? this.validationType : undefined,
                returnCustomerData: "true"
              });

              if (result.exception !== undefined || result.errorCode < 0) {
                setTimeout(() => {
                  this.makeToast('danger', this.$t('notification.SSOLoginError'));
                }, 300);
                await this.$router.push({path: "/ErrorSSO"});
                return;
              }

              if (result.requiresLogin == true) {
                console.log("Routing to LOGIN");
                await this.$router.push({path: "/Login"});
                return;
              }

              if (result.customer == undefined) {
                console.log("JWT Login unsuccesful!");
                if (result.requiresLogin == false) {
                  console.log("A user is already logged in, JWT ignored!");
                  return;
                }
                if (config.FORCE_LOGIN_BEFORE_USE) {
                  console.log("Routing to LOGIN");
                  await this.$router.push({path: "/Login"});
                  return;
                }
              }

              if (!Utils.isEmpty(this.code)) {
                window.preselectedShopCode = this.code;
              }

              this.isLoading = false;
              this.showChoice = true;
              this.customer = result.customer;
              window.customerLoggedIn = true;

              if (config.USE_EXTERNAL_LANGUAGE && window.config.COOKIES_ENABLED) {
                this.$cookies.set('locale', !Utils.isEmpty(result.customer.locale) ? result.customer.locale : config.LANGUAGE_LIST[0].value);
              }

              if (UserTypes.PARENT === result.customer.custom3) {
                console.log("Routing to StudentAccounts");
                window.isParent = true;
                await this.$router.push({path: '/StudentAccounts'});
              }
              else if (!Utils.isEmpty(this.shopId)) {
                console.log("Routing to Menu");
                await this.$router.push({path: "/Menu"});
              }
              else if (config.IS_PURE_ACCOUNTMANAGER) {
                console.log("Routing to Account");
                await this.$router.push({path: "/Account"});
              }
          } else {
            this.showChoice = true;

            this.isLoading = false;

            if (config.IS_PURE_ACCOUNTMANAGER) {
              console.log("Routing to Account");
              await this.$router.push({path: "/Account"});
            }

            if (!config.FORCE_LOGIN_BEFORE_USE) {
              return;
            }
            console.log("forceLogin");
            await this.forceLogin();
          }
        },
        async loadShops() {
          this.isLoading = true;

          const response = await this.doGet('TCPShopsJ.action', { json: "true", emptyBasket: true });
          if (!response || !response.shops || response.shops.length === 0) {
            this.makeToast('danger', this.$t('notification.noShopsAvailable'));
            this.isLoading = false;
            return;
          }
          if (response.emptyBasket) {
            window.shoppingCart = response.basketTile.basketItems;
            window.shoppingTile = response.basketTile;
          }

          this.shops = response.shops;
          this.showChoice = true;

          if (this.shops.length === 1 && this.config.FLAG_AUTOSELECTSHOP) {
            await this.selectShop(this.shops[0].id);
          }

          if (this.shopIdPreselected > 0) {
            console.log("ShopCode was passed! auto-selecting.");
            await this.selectShopCode(this.shopIdPreselected);
          }

          this.isLoading = false;
        },
        async forceLogin() {
          const result = await this.doGet('TCPAfterLoginJ.action', {
            json: "true",
          });

          if (result.requiresLogin == true) {
            console.log("Routing to LOGIN");
            await this.$router.push({path: "/Login"});
            return;
          }

          this.customer = result.customer;

          if (result.customer != undefined) {
            window.isGuestCheckout = result.customer.type != undefined && result.customer.type == 2 ? true : false;
          } else {
            // JP 20221005
            if (window.config.FORCE_LOGIN_BEFORE_USE == true) {
              console.log("Routing to LOGIN");
              await this.$router.push({path: "/Login"});
              return;
            }
          }
        },
        updateShoppingCart: async function () {
          const result = await this.doPost('TCPConfirmOrderJ.action', {
            calculateOrder: window.customerLoggedIn ? true : false,
            orderType: window.customerLoggedIn ? window.orderType : undefined,
            promotionBarcode: window.promoBarcode != undefined ? window.promoBarcode : undefined
          });

          window.shoppingCart = result.basketItems;
          window.subsidy = result.basketTile.paymentsList != undefined ? this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";
          window.xtoken = result.xsrfToken != undefined ? atob(result.xsrfToken) : undefined;
        },
        formatCurrency(value){
            var formatter = new Intl.NumberFormat(this.config.PRICE_NUMBER_FORMAT, {style: 'currency', currency: window.config.SUBSIDY_CURRENCY});
            return formatter.format(value);
        },
        maximizeHeader() {
            var config = window.config;
            if(config.SHOW_GLOBAL_HEADER_BANNER_IMAGE)
            {
                $(".header").addClass("header-big");
                $(".header").addClass("animTransition");
                $(".content").addClass("header-big-content-margin");
                $(".content").removeClass("header-small-content-margin");
            }
           
        },
        minimizeHeader() {
            var config = window.config;
            if(config.SHOW_GLOBAL_HEADER_BANNER_IMAGE)
            {
                $(".header").removeClass("header-big");
                $(".header").addClass("animTransition");
                $(".content").removeClass("header-big-content-margin");
                $(".content").addClass("header-small-content-margin");
            }       
        },
        handleWheelContent(event) {
            if (event.originalEvent !== undefined && Utils.isGreater(event.originalEvent.deltaY, 0))
            {
              this.minimizeHeader();
            }
        },
        async selectShop(shopId, shopCode) {
          this.isLoading = true;
          let config = window.config;

          await this.doGet('TCPSelectShopJ.action', {
            id: shopId,
            json: "true"
          });

          this.isLoading = false;
          window.shopSelected = true;
          window.shop = shopId;
          var shopcopyid = shopId;
          this.selectedShop = this.shops.filter(shop => shop.id.toString() === shopcopyid.toString())[0];
          window.ageVerification = config.AGE_VERIFICATION_SHOPCODES.includes(shopCode) ? true : false;
          window.shopObj = this.selectedShop;

          this.$refs.shopModal.openModal();
        },
        async selectShopCode(shopCode) {
          var config = window.config;
          this.isLoading = true;
          var shopcopycode = shopCode;
          var shop = this.shops.filter(shop => shop.custom2.toString() === shopcopycode)[0];
          if (shop == undefined) {
            console.log("ShopCode " + shopCode + " not found! Shop could not be auto-selected!")
            return;
          }

          await this.doGet('TCPSelectShopJ.action', {
            id: shop.id,
            json: "true"
          });

          this.isLoading = false;
          console.log("Select Shop success: " + shopCode);
          this.selectedShop = this.shops.filter(shop => shop.custom2.toString() === shopcopycode)[0];
          window.shopSelected = true;
          window.shop = this.selectedShop.id;
          window.shopObj = this.selectedShop;
          this.$refs.shopModal.openModal()
          window.ageVerification = config.AGE_VERIFICATION_SHOPCODES.includes(shopCode) ? true : false;
        }
    },
    components: {
        'shop-card' : ShopCard,
        'shop-carousel' : Carousel,
        'loading': VueLoading,
        'modal' : Modal,
        'order-options-page':OrderOptionsPage
    },
}
</script>

<style scoped>
    .shopCardWrapper {
        margin-bottom:50px;
        display:flow-root;
    } 
@media screen and (max-width:770px) {
    .shopCardWrapper {
        display:flow-root;
    }
}

</style>
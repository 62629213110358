// <template>
  <div class="paymentPageWrapper">
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="false"
             loader='bars'></loading>

    <div class="col-12 subPageHeader" style="padding-left: 0px;">
      <h1>{{ $t('title.payment') }}</h1>
    </div>

    <div class="innerScroll">
    <flow :currentPoint="4"></flow>

    <div class="col-12 checkoutPadding">
      <div class="checkoutPadding">
                <div class="col-12 chosenShopTile checkoutTilePadding" v-if="config.SHOW_SHOP_ON_PAYMENT_PAGE">
          <h2>{{shop}}</h2>
          <span v-html="shopAddress"></span>
        </div>

        <div class="col-12 chosenTimeTile checkoutTilePadding" v-if="!immediate && orderType != 'delivery'">
          <span v-html="time"></span>
          <span v-if="TableModeSetting">{{$t('text.tableId')}} </span>
          <span v-if="TableModeSetting" v-html="tableNumber"></span>
        </div>

        <div class="col-12 chosenArticlesTile">

          <div v-for="item in pointCampaigns" v-bind:key="item.campaignId"  style="text-align:right; background:lightgray; display: flow-root; padding: 10px;  border-bottom:0.2px solid var(--main-color);">
            <span style="font-size:25px;">{{ getCampaignName(item.campaignId) }}</span>
            <br/>
            <span v-if="item.gainedPoints > 0">
                            <span style="font-size:15px;"> {{ $t('text.rewardPointsGained') }} </span>
                            <span style="color:var(--main-color); font-size:25px;"> {{ item.gainedPoints }} </span>
                        </span>
            <br/>
            <span v-if="item.usedPoints > 0">
                            <span style="font-size:15px;"> {{ $t('text.rewardPointsBurned') }} </span>
                            <span style="color:var(--main-color); font-size:25px;"> {{ item.usedPoints }} </span>
                        </span>

          </div>
          <br/>

          <table class="shoppingCartItemsTable"  style="table-layout:fixed" v-if="basketLoaded && basketItems.length > 0">
            <thead>
            <th class="shoppingCartHeaderBig">{{ $t('text.article') }}</th>
            <th class="shoppingCartHeaderSmall"></th>
            <th class="shoppingCartHeader">{{ $t('text.sum') }}</th>
            </thead>

            <tr v-for="item in basketItems" v-bind:key="item.id">
              <td class="shoppingCartLine">{{(item.prodText !== undefined) ? item.prodText :item.text}}
                <div v-if="item.hasOptions" style="padding:0;text-align:right;">
                  <span style="font-size:13px;padding:0;" v-html="item.optionText"></span>
                </div>
              </td>
              <td class="shoppingCartLine mainColor" style="text-align:center; font-size:14px;">{{ item.weightOrVolume != undefined ? item.weightOrVolume * 1000 : item.quantity }} {{item.weightOrVolume != undefined ? 'g' : 'x'}} </td>

              <td class="shoppingCartLine mainColor" style="font-weight:600;">
                                <span v-if="item.formattedDiscountedPrice == undefined">
                                    {{item.weightOrVolume != undefined ? item.formattedTotalPrice : item.formattedPrice}}
                                    <span style="font-weight:400; font-size:14px;">{{item.weightOrVolume != undefined ? " - " + item.formattedPrice + "/kg" : ""}}
                                    </span>
                                </span>
                <span v-if="item.formattedTotalDiscountedPrice != undefined">
                                    {{item.weightOrVolume != undefined ? item.formattedPrice : item.formattedTotalDiscountedPrice}}
                                    <span v-if="item.weightOrVolume == undefined" style="color:gray;text-decoration: line-through;">
                                        {{ item.formattedTotalPrice }}
                                    </span>
                                    <span style="font-weight:400; font-size:14px;">{{item.weightOrVolume != undefined ? " - " + item.formattedDiscountedPrice + "/kg" : ""}}
                                    </span>
                                </span>
              </td>
            </tr>
          </table>

          <table class="shoppingCartSummaryTable" v-if="basketLoaded && basketItems.length > 0">
            <thead>
            <th style="width:70%"></th>
            <th style="width:30%"></th>
            </thead>
            <tr>
              <td class="shoppingCartSummaryLine">{{ $t('text.sum') }}</td>
              <td class="shoppingCartSummaryLine">{{basketTile.formattedTotal}}</td>
            </tr>
            <tr v-if="basketTile.paymentsList != undefined">
              <td class="shoppingCartLine" style="color:gray;">{{$t('subTitle.subsidy')}}</td>
              <td class="shoppingCartLine" style="color:gray;">{{formatCurrency(basketTile.paymentsList[0].paymentAmount)}}</td>
            </tr>
          </table>
        </div>
      </div>

      <div v-if="tableMode && !immediate" class="col-12 customerCardBalance checkoutPadding">
        <p class="customerCardBalanceLabel col-4">{{ $t('text.customerCardBalance') }}</p>
        <p class="customerCardBalanceLabel col-8">{{ Utils.formatCurrency(prepayBalanceCash, config.ACCOUNT_BALANCE_CURRENCY) }}</p>
        <!-- TODO refactor modal topup for reuse at this part
        <b-button v-if="config.TOPUP.enabled" class="customerCardBalanceButton btn btn-primary" @click="$refs.topUpModal.openModal()">{{ $t('button.charge') }}</b-button>
        <p v-if="config.TOPUP.enabled != true" class="col-4"></p> -->
      </div>
      <div class="col-12 checkoutPadding" id="paymentMethods" v-for="payment in paymentList" :key="payment.subCode == undefined ? payment.code : (payment.code + '~~' + payment.subCode)">
        <b-button v-if="payment.paymentType == 1" style="margin-top: auto" class="btn btn-primary col-12" v-on:click="pay(payment)">{{ payment.description }}</b-button>
        <b-button v-if="payment.paymentType == 4" style="margin-top: auto" class="btn btn-primary col-12" v-on:click="pay(payment)">{{ $t('button.creditcard') }}</b-button>
        <b-button v-if="payment.paymentType == 5" style="margin-top: auto" class="btn btn-primary col-12" :disabled="prepayBalanceCash == 0 || prepayBalanceCash < basketTile.basketTotal" v-on:click="pay(payment)">{{ $t('button.customercard') }}</b-button>
        <b-button v-if="payment.paymentType == 50" style="margin-top: auto" class="btn btn-primary col-12" v-on:click="pay(payment)">{{ payment.description }}</b-button>
        <b-button v-if="showFreeButton && payment.code == 'free'" style="margin-top: auto" class="btn btn-primary col-12" v-on:click="pay(payment)">{{ $t('button.submit') }}</b-button>
      </div>
      <div class="col-12 checkoutPadding">
        <b-button class="col-12 btn btn-primary" style="margin-top: auto" @click="payLater" v-if="config.QRLANDING_OPEN_TABLES_ENABLE == true">{{$t('button.payLater')}}</b-button>
      </div>

      <div class="forwardBackward">
        <b-button class="btn btn-primary" @click="goBack(false)" style="margin-left: 0" >{{$t('button.back')}}</b-button>
      </div>

    </div>

    <credit-card-payment-saferpay v-if='showSaferpay == true' :subCode="currentPaymentSubCode" ></credit-card-payment-saferpay>
    <credit-card-payment-securepay v-if='showSecurepay == true' :subCode="currentPaymentSubCode" ></credit-card-payment-securepay>
    <credit-card-payment-novalnet v-if='showNovalnet == true' :subCode="currentPaymentSubCode" ></credit-card-payment-novalnet>
    <credit-card-payment-payone v-if='showPayone == true' :subCode="currentPaymentSubCode" :callback="closePayOne" ></credit-card-payment-payone>
    <credit-card-payment-adyen v-if='showAdyen == true'></credit-card-payment-adyen>

    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import CreditCardPaymentComponentSaferpay from '../main-components/CreditCardPaymentComponentSaferpay.vue'
import CreditCardPaymentComponentSecurepay from '../main-components/CreditCardPaymentComponentSecurepay.vue'
import CreditCardPaymentComponentNovalnet from '../main-components/CreditCardPaymentComponentNovalnet.vue'
import CreditCardPaymentComponentPayone from '../main-components/CreditCardPaymentComponentPayone.vue'
import CreditCardPaymentComponentAdyen from '../main-components/CreditCardPaymentComponentAdyen'
import VueLoading from 'vue-loading-overlay'
import FlowIndicator from '../main-components/FlowIndicator.vue'
import Swal from "sweetalert2";
import Utils from "../../utils/utils";
//import { paymentMixin } from "../mixins/paymentMixin";

export default {
  /*mixins: [
      paymentMixin
  ],*/
  data() {
    return {
      prepayBalanceCash: 0,
      showSaferpay: false,
      showSecurepay: false,
      showNovalnet: false,
      showPayone: false,
      showAdyen: false,
      paymentList: [],
      currentPayment: "",
      paymentFinished: false,
      isLoading: false,
      shop: "",
      shopAddress: "",
      comment:"",
      time: "",
      basketTile: {
        Type:Object
      },
      tableMode: false,
      immediate: false,
      // pointCampaigns: [],
      pointCampaignsDefinitions: [],
      basketLoaded: false,
      showFreeButton: false,
      currentPaymentSubCode: "",
      firstClickDone: false,
    }
  },
  components: {
    'credit-card-payment-saferpay': CreditCardPaymentComponentSaferpay,
    'credit-card-payment-securepay': CreditCardPaymentComponentSecurepay,
    'credit-card-payment-novalnet': CreditCardPaymentComponentNovalnet,
    'credit-card-payment-payone': CreditCardPaymentComponentPayone,
    'credit-card-payment-adyen': CreditCardPaymentComponentAdyen,
    'loading': VueLoading,
    'flow' : FlowIndicator
  },
  computed:{
    Utils() {
      return Utils
    },
    currentlySelectedPayment (){
      if(this.currentPayment == "Saferpay")
      {
        console.log(this.currentPayment + "~~" + this.currentPaymentSubCode);
        return this.currentPayment + "~~" + this.currentPaymentSubCode;
      }
      else if(this.currentPayment == "PayOne")
      {
        if(this.currentPaymentSubCode !== "")
        {
          return this.currentPayment + "~~" + this.currentPaymentSubCode;
        }
        else
        {
          return this.currentPayment;
        }
      }
      else
      {
        return this.currentPayment;
      }
    },
    pointCampaigns(){
      return this.basketTile.pointCampaigns;
    },
    basketItems(){
      return this.basketTile.basketItems;
    },
    orderType () {
      return window.orderType;
    },
    TableModeSetting () {
      return window.TableMode;
    },
    Immediate () {
      return window.immediate;
    },
    config(){
      return window.config;
    },
    tableId(){
      return window.tableId;
    },
    tableNumber() {
      return window.tableModeTable;
    }
  },
  async beforeMount() {
    var vueInstance = this;

    const result = await vueInstance.doGet( 'TCPAfterLoginJ.action', { json: true });

    if (result.exception !== undefined) {
      vueInstance.$router.go(-1);
      return;
    }

    if (result.wondCustomer != undefined) {
      var config = window.config;
      if (result.wondCustomer.cardType.toUpperCase() === "CREDIT") {
        vueInstance.prepayBalanceCash = (result.wondCustomer.creditLimit - result.wondCustomer.creditBalance).toFixed(config.CUSTOMER_BALANCE_DECIMAL_PLACES);
      } else {
        vueInstance.prepayBalanceCash = (result.wondCustomer.prepayBalanceCash + result.wondCustomer.prepayBalanceVoucher + result.wondCustomer.prepayBalanceBonus).toFixed(config.CUSTOMER_BALANCE_DECIMAL_PLACES);
      }
    }

    vueInstance.confirmOrder(false);
    vueInstance.tableMode = vueInstance.TableModeSetting;
    vueInstance.immediate = vueInstance.Immediate;
    vueInstance.comment = window.comment;
    vueInstance.pointCampaignsDefinitions = window.pointCampaignsDefinitions;
  },
  mounted() {
    Utils.adjustInnerScrollHeight();
    window.addEventListener('resize', Utils.adjustInnerScrollHeight);
  },
  beforeDestroy() {
    // Event-Listener entfernen, um Speicherlecks zu vermeiden
    window.removeEventListener('resize', Utils.adjustInnerScrollHeight);
  },
  methods:{
    closePayOne(status){
      if(status >= 0)
      {
        this.$router.push({ path: '/CheckoutFinished' });
      }
    },
    formatCurrency(value){
      // switch (this.config.) {
      //     case value:

      //         break;

      //     default:
      //         break;
      // }
      // var formatter = new Intl.NumberFormat('de-DE', { maximumSignificantDigits: 4});
      // return formatter.format(value);
      var formatter = new Intl.NumberFormat('de-DE', {style: 'currency', currency: window.config.SUBSIDY_CURRENCY});
      return formatter.format(value);
    },
    startDeliveryFeeProcess(){
      var config = window.config;
      var deliveryFeeArticleId = 0;
      //TODO: GET ARTICLE ID THROUGH TCPPRODUCTSEARCH AND CALL ADDMENUITEM TO ADD DELIVERY ARTICLE TO TRANSACTION IF DELIVERY FEES ARE ENABLED
      if(config.DELIVERY_FEE_ENABLED == true)
      {
        if(config.DELIVERY_FEE_TRANSACTION_TRESHOLD != undefined && config.DELIVERY_FEE_TRANSACTION_TRESHOLD != null && config.DELIVERY_FEE_TRANSACTION_TRESHOLD != 0)
        {
          if(config.DELIVERY_FEE_TRANSACTION_TRESHOLD > this.basketTile.basketTotal)
          {
            deliveryFeeArticleId = config.DELIVERY_FEE_TCPOS_ARTICLE_ID;
            this.getProductId(deliveryFeeArticleId);
          }
        }
        else
        {
          deliveryFeeArticleId = config.DELIVERY_FEE_TCPOS_ARTICLE_ID;
          this.getProductId(deliveryFeeArticleId);
        }
      }
    },
    async getProductId(articleId, remove = false) {
      var config = window.config;
      var vueInstance = this;
      if (config.DELIVERY_FEE_TCPOS_ARTICLE_ID == null) {
        vueInstance.confirmOrder(true);
        return;
      }

      const result = await vueInstance.doGet('TCPProductSearchJ.action', {
        dataDescriptor: {
          custom1Int: articleId,
        },
        json: 'true',
        orderType: window.orderType,
        productSearch: {
          custom1Int: articleId,
          priceTypeRequired: "DE",
        }
      });

      if (result.products.productArray != undefined) {
        if (result.products.productArray.length > 0) {
          var productId = result.products.productArray[0].id;
          //CHECK IF DELIVERY FEE ARTICLE ALREADY PRESENT
          if (vueInstance.basketTile.basketItems.filter(function (x) {
            return x.prodId == productId
          }).length > 0) {
            console.log("Delivery Fee already added, abort.");
            if (remove) {
              vueInstance.removeDeliveryCost(vueInstance.basketTile.basketItems.filter(function (x) {
                return x.prodId == productId
              })[0].id, productId);
            } else {
              vueInstance.confirmOrder();
            }

            return;
          } else {
            //IF DELIVERY FEE ARTICLE NOT IN BASKET, ADD IT
            vueInstance.addDeliveryCost(productId);
          }
        }
      } else {
        return 0;
      }
    },
    async addDeliveryCost(deliveryFeeProductId) {
      var vueInstance = this;
      var config = window.config;

      if (window.orderType == "dinein" || window.orderType == "takeaway") {
        //if no delivery fee should be added, submit the order confirmation
        vueInstance.confirmOrder(true);
        return;
      }

      console.log("delivery fee product id:" + deliveryFeeProductId);
      if (config.DELIVERY_FEE_ENABLED == true) {
        if (config.DELIVERY_FEE_TRANSACTION_TRESHOLD != undefined && config.DELIVERY_FEE_TRANSACTION_TRESHOLD != null && config.DELIVERY_FEE_TRANSACTION_TRESHOLD != 0) {
          if (config.DELIVERY_FEE_TRANSACTION_TRESHOLD <= this.basketTile.basketTotal) {
            //if no delivery fee should be added, submit the order confirmation
            vueInstance.confirmOrder(true);
            return;
          }
        }
      } else {
        //if no delivery fee should be added, submit the order confirmation
        vueInstance.confirmOrder(true);
        return;
      }

      vueInstance.isLoading = true;

      const result = await vueInstance.doPost('TCPAddMenuItemToCartJ.action', {
        productId: deliveryFeeProductId,
        json: "true",
        orderType: "delivery",
        quantity: 1,
        calculateOrder: window.customerLoggedIn ? true : false
      });

      vueInstance.isLoading = false;

      vueInstance.basketItems = result.basketTile.basketItems;
      vueInstance.basketTile = result.basketTile;
      vueInstance.confirmOrder(true);
    },
    async removeDeliveryCost(basketId, productId) {
      var vueInstance = this;

      if (basketId > 0) {
        const result = await vueInstance.doPost('TCPRemoveMenuItemFromCartJ.action', {
          id: basketId,
          orderType: window.orderType
        });

        if (result.exception !== undefined) {
          vueInstance.makeToast('danger', this.$t('removeBasketItemFail') + result.exception.message);
          return;
        }

        window.shoppingCart = result.basketTile.basketItems;
        window.subsidy = result.basketTile.paymentsList != undefined ? vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";
        vueInstance.basketItems = result.basketTile.basketItems;
        vueInstance.addDeliveryCost(productId);
      }
    },
    getCampaignName(campaignId){
      for(var i  = 0; i < this.pointCampaignsDefinitions.length; i++)
      {
        if(this.pointCampaignsDefinitions[i].id == campaignId)
        {
          return this.pointCampaignsDefinitions[i].description;
        }
      }

      return "";
    },
    async preparePayments() {
      var vueInstance = this;
      const result = await vueInstance.doGet('TCPPaymentJ.action', { json: true });

      if (result.page == "tcp.catalog.login.page") {
        vueInstance.$router.push({path: '/Login'});
        return;
      }

      vueInstance.shopAddress = result.formattedShopAddr;
      vueInstance.shop = result.shopName;
      vueInstance.time = result.formattedPickupTime;
      window.orderDateTime = result.formattedPickupTime;

      if (result.paymentDetails == undefined) {
        vueInstance.showFreeButton = true;
        return;
      } else {
        vueInstance.showFreeButton = false;
      }

      vueInstance.paymentList = result.paymentDetails;

      if (vueInstance.paymentList.length == 1) {
        var element = new Object;
        element.value = vueInstance.paymentList[0].code;
        element.subCode = vueInstance.paymentList[0].subCode;
        console.log(element);
        vueInstance.onPaymentSelect(element);
      }

      window.onmessage = async function (e) {
        let result = e.data.toString();
        if (result == "S" || result == "A" || result == "F" || result.startsWith("payoneA") || result.startsWith("payoneB") || result.startsWith("payoneD")) {
          vueInstance.isLoading = true;
        }

        if (result == "S") {
          if (vueInstance.currentPaymentSubCode == "T") {
            await vueInstance.doGet('ProcessSaferpayJ.action', { json: true, vresult: result });
            vueInstance.isLoading = false;
          }
          vueInstance.$router.push({path: '/CheckoutFinished'});
        } else if (result == "A") {
          vueInstance.showSaferpay = false;
          vueInstance.showSecurepay = false;
          vueInstance.showNovalnet = false;
          vueInstance.showPayone = false;
          vueInstance.showAdyen = false;
          vueInstance.isLoading = false;

        } else if (result == "F") {
          vueInstance.makeToast('danger', vueInstance.$t('notification.paymentError'));
          vueInstance.showSaferpay = false;
          vueInstance.showSecurepay = false;
          vueInstance.showNovalnet = false;
          vueInstance.showPayone = false;
          vueInstance.showAdyen = false;
          vueInstance.isLoading = false;

        } else if (result.startsWith("payoneA")) {
          await vueInstance.doGet('ProcessPayOneJ.action', { json: true, vresult: result.replace('payone', '') });
          vueInstance.isLoading = false;
          vueInstance.$router.push({path: '/CheckoutFinished'});
        } else if (result.startsWith("payoneB")) {
          vueInstance.makeToast('danger', vueInstance.$t('notification.paymentAborted'));
          vueInstance.showSaferpay = false;
          vueInstance.showSecurepay = false;
          vueInstance.showNovalnet = false;
          vueInstance.showPayone = false;
          vueInstance.showAdyen = false;
          vueInstance.isLoading = false;
          vueInstance.$refs.externalPaymentModal.closeModal();
        } else if (result.startsWith("payoneD")) {
          vueInstance.makeToast('danger', vueInstance.$t('notification.paymentError'));
          vueInstance.showSaferpay = false;
          vueInstance.showSecurepay = false;
          vueInstance.showNovalnet = false;
          vueInstance.showPayone = false;
          vueInstance.showAdyen = false;
          vueInstance.isLoading = false;
          vueInstance.$refs.externalPaymentModal.closeModal();
        } else if (result.startsWith("novalnet")) {
          vueInstance.isLoading = false;
          vueInstance.$router.push({path: '/CheckoutFinished'});
        }
      }
    },
    async confirmOrder(submit = true) {
      var vueInstance = this;

      this.isLoading = true;

      var url = submit ? 'TCPConfirmOrderSubmitJ.action' : 'TCPConfirmOrderJ.action';
      const result = await vueInstance.doPost(url, {
        json: "true",
        orderType: window.orderType,
        comment: submit ? window.comment : undefined,
        promotionBarcode: submit && window.promotionBarcode != undefined ? window.promotionBarcode : undefined,
        calculateOrder: !submit ? true : undefined,
        xsrfToken: submit && window.xtoken != undefined ? btoa(window.xtoken) : undefined,
        tableId: submit && vueInstance.TableModeSetting ? window.tableId : undefined
      });

      if (result.xsrfToken != undefined) {
        window.xtoken = atob(result.xsrfToken);
      }

      if (result.exception !== undefined) {
        vueInstance.isLoading = false;
        return;
      }

      if (result.requiresLogin == true) {
        vueInstance.isLoading = false;
        return;
      }

      window.shoppingCart = result.basketTile.basketItems;
      window.subsidy = result.basketTile.paymentsList != undefined ? vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";

      vueInstance.basketTile = result.basketTile;
      vueInstance.isLoading = false;

      if (result.basketTile.pointCampaigns != undefined) {
        vueInstance.pointsGained = result.basketTile.pointCampaigns.gainedPoints;
        vueInstance.pointsBurned = result.basketTile.pointCampaigns.usedPoints;
      }

      let pickupOrderNumber = result.pickupNumber;
      if (pickupOrderNumber != undefined) {
        window.orderPickupNumber = pickupOrderNumber;
      }

      vueInstance.basketLoaded = true;

      if (submit)
        vueInstance.preparePayments();

      //DELIVERY FEE
      if (submit == false) {
        vueInstance.removePreviousDeliveryFees();
      }
    },
    removePreviousDeliveryFees(){
      var vueInstance = this;
      var config = window.config;
      //TODO: REMOVE DELIVERY FEES USING getProductId
      vueInstance.getProductId(config.DELIVERY_FEE_TCPOS_ARTICLE_ID, true)
    },
    goBack(){
      this.$router.go(-1);
    },
    onPaymentHide(){
      this.showSaferpay = false;
      this.showSecurepay = false;
      this.showNovalnet = false;
      this.showPayone = false;
      this.showAdyen = false;
      this.currentPayment = "";
    },
    onPaymentSelect(element){
      $(":checkbox[ident!=" + element.value.replace('~~', "") + "]").prop("checked", false);
      this.showSaferpay = false;
      this.showSecurepay = false;
      this.showNovalnet = false;
      this.showPayone = false;
      this.showAdyen = false;

      this.currentPayment = element.value;
      console.log("current payment method: " + this.currentPayment);
      console.log("current subcode:" + element.subCode);

      if(element.subCode != undefined)
      {
        if(element.subCode == "T")
        {
          this.currentPaymentSubCode = element.subCode;
        }
        else if(element.subCode == "P")
        {
          this.currentPaymentSubCode = element.subCode;
        }
            // else if(element.subCode == "PDT")
            // {
            //     this.currentPaymentSubCode = element.subCode;
        // }
        else
        {
          element.subCode = "";
          this.currentPaymentSubCode = "";
        }
      }
      else
      {
        element.subCode = "";
        this.currentPaymentSubCode = "";
      }

      if(this.currentlySelectedPayment != "customercard")
      {
        this.onSubmit(true);
      }
    },
    async onSubmit(checkCod) {
      // AP 20220504 The vue-loading-overlay does not always correctly block multiple submits
      // Therefore a second failsafe check is needed to stop multiple submits.
      if (this.isLoading) {
        return;
      }

      var vueInstance = this;
      vueInstance.showSaferpay = false;
      vueInstance.showSecurepay = false;
      vueInstance.showNovalnet = false;
      vueInstance.showPayone = false;
      vueInstance.showAdyen = false;

      if (this.currentlySelectedPayment == "customercard" && this.basketTile.basketTotal > this.prepayBalanceCash) {
        this.makeToast('danger', this.$t("balanceNotEnough"));
        return;
      }

      if ((this.currentlySelectedPayment == "cod" || this.currentlySelectedPayment == "customercard" || this.currentlySelectedPayment == "TCPDebitor") && checkCod) {
        return;
      }

      this.isLoading = true;

      const result = await vueInstance.doPost('TCPPaymentSubmitJ.action', {
        deliveryAddress: "0",
        paymentMethod: this.currentlySelectedPayment,
        json: "true",
        xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
      });

      if (result.paymentMethod == "Saferpay~~P" || result.paymentMethod == "Saferpay~~T") {
        vueInstance.isLoading = false;
        console.log(vueInstance.currentPayment);
        vueInstance.showSaferpay = true;
        vueInstance.$forceUpdate();
        return;
      }

      if (result.paymentMethod == "Securepay") {
        vueInstance.isLoading = false;
        console.log(vueInstance.currentPayment);
        vueInstance.showSecurepay = true;
        vueInstance.$forceUpdate();
        return;
      }

      if (result.paymentMethod == "Novalnet") {
        vueInstance.isLoading = false;
        console.log(vueInstance.currentPayment);
        vueInstance.showNovalnet = true;
        vueInstance.$forceUpdate();
        return;
      }

      if (result.paymentMethod == "PayOne") {
        vueInstance.showPayone = true;
        console.log(vueInstance.currentPayment);
        vueInstance.isLoading = false;
        vueInstance.$forceUpdate();

        setTimeout(() => {
          var objDiv = document.getElementsByClassName("container")[0];
          var iFrame = document.getElementById("payoneCheckoutContainer");
          objDiv.scrollTop = objDiv.scrollHeight;
          iFrame.scrollIntoView({behavior: "smooth"});
        }, 300);

        return;
      }

      if (result.paymentMethod == "Adyen") {
        vueInstance.isLoading = false;
        console.log(vueInstance.currentPayment);
        vueInstance.showAdyen = true;
        vueInstance.$forceUpdate();
        return;
      }

      if (result.paymentMethod == "StripeModule") {
        return;
      }

      if (result.exception !== undefined) {
        vueInstance.isLoading = false;
        vueInstance.makeToast('danger', this.$t("exceptionMessage"));
        return;
      }

      if (result.page == "tcp.catalog.checkoutfinished.page") {
        if (vueInstance.currentlySelectedPayment == "cod" || vueInstance.currentlySelectedPayment == "customercard" || vueInstance.currentlySelectedPayment == "free") {
          window.shoppingCart = null;

          await vueInstance.doGet('TCPCheckoutFinishedJ.action', {
            json: "true"
          });
        }

        vueInstance.isLoading = false;
        window.shoppingCart = undefined;
        vueInstance.$router.push({path: '/CheckoutFinished'});
        return;
      }

      vueInstance.isLoading = false;
      window.shoppingCart = null;
      vueInstance.$router.push({path: '/Payment'});
    },
    async payLater() {
      this.isLoading = true;
      var vueInstance = this;

      const result = await vueInstance.doPost('TCPPaymentSubmitJ.action', {
        deliveryAddress: "0",
        paymentMethod: "cod",
        json: "true",
        xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
      });

      if (result.exception !== undefined) {
        vueInstance.isLoading = false;
        vueInstance.makeToast('danger', this.$t("exceptionMessage"));
        return;
      }

      if (result.page == "tcp.catalog.checkoutfinished.page") {
        if (vueInstance.currentlySelectedPayment == "cod" || vueInstance.currentlySelectedPayment == "customercard") {
          await vueInstance.doGet('TCPCheckoutFinishedJ.action', {
            json: "true"
          });
        }

        vueInstance.isLoading = false;
        window.shoppingCart = undefined;
        vueInstance.$router.push({path: '/CheckoutFinished'});
        return;
      }

      vueInstance.isLoading = false;
      window.shoppingCart = null;
      vueInstance.$router.push({path: '/Payment'});
    },
    pay(payment)
    {
      if (payment.subCode != undefined)
      {
          this.currentPaymentSubCode = payment.subCode;
      }
      this.currentPayment = payment.code;

      let checkCod = true;
      if (payment.code == 'code' || payment.code == 'customercard' || payment.code == 'free' || payment.code == 'TCPDebitor')
      {
          checkCod = false;
      }
      this.onSubmit(checkCod);
    }
  },
  async created() {
    var scrollDiv = document.getElementById('contentSection');
    if (scrollDiv) {
      scrollDiv.style.overflowY = 'hidden';
    }
  },
  beforeRouteLeave (to,from,next) {
    var scrollDiv = document.getElementById('contentSection');
    if (scrollDiv) {
      scrollDiv.style.overflowY = 'auto';
    }

    Swal.close();
    next();
  }
}
</script>

<style scoped>
.fixBigScreen{
  max-width:1000px;
}


.paymentSelectTitle{
  margin-bottom:50px;
}

.paymentSelect {
  margin-top: 15px;
  margin-bottom: 15px;
}

.radioButton {
  min-width:50px;
  min-height:30px;
}

.chosenShopTile{
  border: 1px solid var(--body-color);
  border-radius:5px;
  margin:5px;
}

.chosenTimeTile {
  background-color: var(--body-color);
  border-radius:5px;
  margin: 5px;
}

.chosenArticlesTile {
  background-color: var(--body-color);
  border-radius:5px;
  margin: 5px;
  text-align: left;
}

.shoppingCartHeader{
  width:30%;
}

.shoppingCartLine{
  height:40px;
}

.shoppingCartHeaderBig{
  width:60%;
}

.shoppingCartHeaderSmall{
  width:10%;
}

.shoppingCartItemsTable{
  width:100%;
}

.shoppingCartSummaryTable{
  width:100%;
  border-top: 1px solid var(--main-color);
}

.shoppingCartSummaryLine{
  font-weight:600;
}

@media screen and (max-width:768px) {
  .chosenShopTile{
    margin:0px;
    margin-bottom:5px;
  }
  .chosenTimeTile {
    margin:0px;
    margin-bottom:5px;
  }
  .chosenArticlesTile {
    margin:0px;
    margin-bottom:5px;
  }
}

.forwardBackward {
  border-top: 1px solid var(--main-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.innerScroll{
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 280px);
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .innerScroll {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 220px);
    width: 100%;
  }
}

#content-wrapper
{
  overflow-y: hidden;
}

</style>
<template>

  <div id="app" class="col-12" tabindex="-1">

    <div v-show="appIsIdle" @click="refreshPage" style="position:fixed;width:100%; height:100%;top:0;left:0;right:0;bottom:0; background:rgba(108, 122, 137, 0.8) ; color:var(--main-color-highlight); z-index:99999999999999999999999999999999999999999999999999999;">  
      <div style="padding-top:15vh;">
        {{ $t('text.sessionTerminated') }}
      </div>
      <img class="appIdleIcon" src="@/assets/images/excl-point.png"/>
    </div>

    <div v-bind:class="{ appIsIdle: appIsIdle}">

    <div style="position: fixed; z-index: 99999; left:2%" ><a class="sr-only sr-only-focusable bg-dark text-light" href="#main">Skip to main content</a></div>

      <div id="ControlGroupWrapperFixed" class="fixed-div fixed-right" v-show="config.SHOW_GLOBAL_CONTROL_GROUP">
      <control-group-fixed :config="config" :changeLocale="changeLocale" :itemsQuantity="itemsQuantity"></control-group-fixed>
      </div>

      <header class="noMargin fixed-div header" role="banner">
      <global-header  v-show="config.SHOW_GLOBAL_HEADER" :menus="getMenus" :config="config" :changeLocale="changeLocale"> </global-header>
      </header>
      <main role="main" id="main">
        <div class="oneWayScroll content col-12" id="contentSection">

        <global-left v-show="config.SHOW_GLOBAL_LEFT" :config="config" :menus="getMenus" class="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 col-2" id="global-left-wrapper"></global-left> 
          <!--
            ID=MobileRight -> When running on mobile hardware, the section to the right of the content must come before the content section because responsivity
            is achieved by stacking columns one under another.
            If we allow that and dont switch to the Section with ID=MobileRight, then the section to the right (ID=DesktopRight) gets pushed to the end of the page
          -->
        <global-right v-show="config.SHOW_GLOBAL_RIGHT" :config="config" :menus="getMenus" id="MobileRight" class="col-2 col-md-2"></global-right>

          <!--
            This is where pages get rendered, managed by the vue-router module
          -->

          <router-view id="content-wrapper" class="oneWayScroll col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          </router-view>
          <!--
            ID=DesktopRight -> When running on mobile hardware, the section to the right of the content must come before the content section because responsivity
            is achieved by stacking columns one under another.
            If we allow that and dont switch to the Section with ID=MobileRight, then the section to the right (ID=DesktopRight) gets pushed to the end of the page
          -->
          <!-- <global-right v-show="config.SHOW_GLOBAL_RIGHT" :config="config" :menus="getMenus" id="DesktopRight" class="col-12 col-md-2 col-lg-custom-right"></global-right> -->

        </div>
      </main>
      <footer id="footer" role="contentinfo" class="noMargin col-12" style="padding:0;margin:0;">
        <global-footer :footerLinks="config.FOOTER.links" :config="config" :changeLocale="changeLocale"></global-footer>
      </footer>
    </div>
  </div>
</template>

<script>
import GlobalHeader from './components/layout-components/GlobalHeader.vue'
import GlobalFooter from './components/layout-components/GlobalFooter.vue'
import GlobalLeft from './components/layout-components/GlobalLeft.vue'
import GlobalRight from './components/layout-components/GlobalRight.vue'
import ControlGroupFixed from './components/main-components/ControlGroupFixed.vue'
import $ from 'jquery'
import 'vue-loading-overlay/dist/vue-loading.css'
import './css/app.scss'
import Utils from "@/utils/utils";

export default {
  name: 'App',
  computed: {
    config() {
      return window.config;
    },
    getMenus() {
      return this.config.MENU_CONTENT;
    },
    itemsQuantity() {
      if (window.shoppingCart !== undefined) {
        return window.shoppingCart.length;
      } else {
        return 0;
      }
    }
  },
  components: {
    'global-header': GlobalHeader,
    'global-footer': GlobalFooter,
    'global-left': GlobalLeft,
    'global-right': GlobalRight,
    'control-group-fixed': ControlGroupFixed,
  },
  destroyed() {
    document.getElementById("app").removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    refreshPage() {
      document.location.reload();
    },
    handleWheel(event) {
        if (event != undefined)
        {
          if (event.originalEvent.deltaY < 0)
          {
          this.maximizeHeader();
          }
          else if (event.originalEvent.deltaY > 0)
          {
          this.minimizeHeader();
        }
      }
    },
    handleWheelContent() {
      console.log(event.originalEvent.deltaY);
      if (event.originalEvent.deltaY < 0 && $("#content-wrapper").scrollTop() == 0)
      {       
        this.maximizeHeader();
      }
      else if (event.originalEvent.deltaY > 0)
      {
        if($(".header").hasClass("header-big"))
        {
          var myDiv = document.getElementById('content-wrapper');

          myDiv.scrollTop = 0;
          this.minimizeHeader();
        }
      }
    },
    maximizeHeader() {
      var config = window.config;
      if(window.location.href.includes('/Shop') && config.SHOW_GLOBAL_HEADER_BANNER_IMAGE)
      {
        $(".header").addClass("header-big");
        $(".header").addClass("animTransition");
        $(".content").addClass("header-big-content-margin");
        $(".content").removeClass("header-small-content-margin");
      }

    },
    minimizeHeader() {
      $(".header").removeClass("header-big");
      $(".header").addClass("animTransition");
      $(".content").removeClass("header-big-content-margin");
      $(".content").addClass("header-small-content-margin");
    },
    handleScroll() {
      if ($("#app").scrollTop() == 0) {
        this.maximizeHeader();
      }
      else {
        this.minimizeHeader();
      }

      // Any code to be executed when the window is scrolled
    },
    handleScrollContent() {
      if ($("#content-wrapper").scrollTop() == 0) {
        this.maximizeHeader();
      }
      else {
        this.minimizeHeader();
      }
    },
    async changeLocale(locale) {
      this.$i18n.locale = locale;
      let vueInstance = this;
      var config = window.config;

      if (Utils.isEmpty(window.locale)) {
        await vueInstance.doPost('TCPSetLocaleJ.action', {
          appLocale: locale,
          "json": "true"
        });
      }

      window.locale = locale;
      document.documentElement.lang = config.LANGUAGE_LIST.find(lang => lang.value === locale).tag;

      if (window.timeString != null && window.timeString != undefined)
        window.timeString = window.timeString + ' ';
    },
  },
  data() {
    return {
      isConfigLoaded: false,
      configLoaded: {},
      mobileView: true,
      showNav: false,
      appIsIdle: false,
      intervalId: 0,
      appIsIdleBlur: "appIdleBlur"
    }
  },
  async created() {
    /*this.configLoaded = await loadConfig();
    Vue.prototype.$config = this.configLoaded;
    this.isConfigLoaded = true;

    console.log('Created App.vue ' + this.$config.apiEndpoint);*/
  },
  async mounted() {
    var vueInstance = this;
    var config = window.config;

    //Version Number:
    console.log("2.0.52");

    const element = document.getElementById('app');
    if (element) {
      element.focus();  // Setzt den Fokus auf das Element mit der ID `pageContainer`
    }

    if (window.locale == undefined || window.locale == null) {
      // Check cookies:
      if (window.config.COOKIES_ENABLED) {
        if (this.$cookies.isKey('TableMode')) {
          const tableModeValue = this.$cookies.get('TableMode');
          const isTableMode = tableModeValue === true || tableModeValue.toString().toUpperCase() === "TRUE";

          if (isTableMode) {
            window.TableMode = true;
            console.log("tableMode " + window.TableMode);

            // Merge table mode configurations into the global config
            Object.assign(window.config, window.configTablemode);
          }
        }

        if (this.$cookies.isKey('tableId')) {
          window.tableId = this.$cookies.get('tableId');
          window.tableModeTable = this.$cookies.get('tableId');
        } else {
          // RETURN TO TABLE LANDING?
        }
        if (this.$cookies.isKey('shopId')) {
          window.shopId = this.$cookies.get('shopId');
        }
        if (this.$cookies.isKey('orderType')) {
          window.orderType = this.$cookies.get('orderType');
        }
        if (this.$cookies.isKey('immediate')) {
          window.immediate = this.$cookies.get('immediate');
        }
        if (this.$cookies.isKey('locale')) {
          window.locale = this.$cookies.get('locale');
        }
      }

      if (!window.tableModeTable) {
        let currentLocale = !Utils.isEmpty(window.locale) ? window.locale : config.LANGUAGE_LIST[0].value;
        this.$i18n.locale = currentLocale;

        await vueInstance.doPost('TCPSetLocaleJ.action', {
          appLocale: currentLocale,
          json: "true"
        });

        window.locale = currentLocale;
        document.documentElement.lang = config.LANGUAGE_LIST.find(lang => lang.value === currentLocale).tag;
      }

      if (window.timeString != null && window.timeString != undefined)
        window.timeString = window.timeString + ' ';
    }

    vueInstance.intervalId = setInterval(() => {
      var cookiesEnabled = true;
      var cookieEnabled = navigator.cookieEnabled;

      // When cookieEnabled flag is present and false then cookies are disabled.
      if (cookieEnabled === false) {
        cookiesEnabled = false;
      }

      // try to set a test cookie if we can't see any cookies and we're using
      // either a browser that doesn't support navigator.cookieEnabled
      // or IE (which always returns true for navigator.cookieEnabled)
      if (!document.cookie && (cookieEnabled === null || /*@cc_on!@*/false)) {
        document.cookie = "testcookie=1";

        if (!document.cookie) {
          cookiesEnabled = false;
        } else {
          document.cookie = "testcookie=; expires=" + new Date(0).toUTCString();
        }
      }

      console.log(cookiesEnabled);

      if (window.REQUEST_TIMESTAMP == undefined) {
        // console.log("Could not find request timestamp");
        console.log("Could not find request timestamp, session stopped!");
        vueInstance.appIsIdle = true;
        return;
      }

      var datetimeStampNow = new Date().getTime();
      window.remainingSessionTime = window.REQUEST_TIMESTAMP + window.config.IDLE_TIMER_MILISECONDS - datetimeStampNow;

      //Update document title
      if (vueInstance.config.USE_EXTENDED_PAGETITLE && vueInstance.config.IS_PURE_ACCOUNTMANAGER) {
        var remainingTimeMessage = "";

        if (vueInstance.config.SHOW_TITLE_LOGOUT_TIMER) {
          var remainingTimeInMillisecounds = window.remainingSessionTime;
          if (remainingTimeInMillisecounds > 0) {
            var timeString = "";
            if ((remainingTimeInMillisecounds / 60000) > 1) {
              timeString = Math.round(remainingTimeInMillisecounds / 60000) + " min";
            } else {
              timeString = "< 1 min";
            }
            remainingTimeMessage = this.$t('text.timeRemaining') + "" + timeString + " - ";
          }
        }

        document.title = remainingTimeMessage + this.$t('title.webShop') + " - " + vueInstance.$route.name;
      }

      if (datetimeStampNow - vueInstance.config.IDLE_TIMER_MILISECONDS > window.REQUEST_TIMESTAMP) {
        vueInstance.appIsIdle = true;
      }

    }, 1000);


    //BIND EVENTS
    $("#app").bind("scroll", function () {
      vueInstance.handleScroll();
    });

    $("#siteHeader").bind("wheel", function (event) {
      vueInstance.handleWheel(event);
    });

    $("#content-wrapper").bind("wheel", function (event) {
      vueInstance.handleWheelContent(event);
    });

    $("#content-wrapper").bind("scroll", function (event) {
      vueInstance.handleScrollContent(event);
    });

    if (config.SHOW_GLOBAL_HEADER_BANNER_IMAGE == true) {
      $("#siteHeader").bind("click", function () {
        if ($('#siteHeader').hasClass('header-big')) {
          vueInstance.minimizeHeader();
        } else {
          vueInstance.maximizeHeader();
        }
      });
    }

    if (!window.location.href.includes('/PaymentEnd') && !window.location.href.includes('/Login') && !window.location.href.includes('/QRLanding') && !window.location.href.includes('/Confirm')) {
      // AP 20220509 T60190W WAM redirect to Account instead of /Shop
      if (!config.IS_PURE_ACCOUNTMANAGER) {
        if (config.TABLE_CHECKOUT.enabled) {
          if (window.shopId != undefined && window.immediate) {
            let path = "/QRLanding?s=" + window.shopId + "&i=" + window.immediate;
            await vueInstance.$router.push({path: path});
          } else if (window.shopId != undefined && window.tableId != undefined) {
            //shop und tableid vorhanden?
            if (window.jwt != undefined || window.ssoCode != undefined) {
              //mit shop tableid und dem  logincde zum ding senden
              let path = "/QRLanding?s=" + window.shopId + "&t=" + window.tableId;
              if (window.jwt != undefined) {
                path = path + "&id_token=" + window.jwt;
              } else if (window.ssoCode != undefined) {
                path = path + "&code=" + window.ssoCode;
              }

              window.jwt = undefined;
              window.ssoCode = undefined;
              await vueInstance.$router.push({path: path});
            } else {
              //nur mit shop und tableid senden
              let path = "/QRLanding?s=" + window.shopId + "&t=" + window.tableId;
              await vueInstance.$router.push({path: path});
            }
          } else {
            await vueInstance.$router.push({path: "/" && vueInstance.config.TABLE_CHECKOUT.homepage });
          }
        } else {
          await vueInstance.$router.push({path: '/Shop'});
        }
      } else {
        await vueInstance.$router.push({path: '/Account'});
      }
    }
  }
}
</script>
<style scoped>
#app:focus {
  outline: none;
}
</style>

<template>
    <div class="checkoutFinishedWrapper" v-if="!isInIFrame">   
        <!-- <flow :currentPoint="5"> </flow> -->

        <div class="fontTitle" style="text-align:center;">
            {{ $t('subTitle.checkoutFinishedMessage') }}
        </div>
    

        <div class="fontOrderSuccessMessage" v-if="orderTypeComputed == 'delivery' && !tableMode">
            {{ $t('text.checkoutFinishedMessageBodyDelivery') }}
        </div>

        <div class="fontOrderSuccessMessage" v-if="orderTypeComputed == 'takeaway' && !tableMode">
            {{ $t('text.checkoutFinishedMessageBodyTakeaway') }}
            <br/>
            {{ orderDateTime }}
            <br/>
            <br/>
            <div>
                {{ $t('text.pickupSpot') }}:
                <br/>
                {{ shop.name }}
                <br/>
                <span v-html="shop.custom6"></span>
            </div>
        </div>

        <div class="fontOrderSuccessMessage" v-if="orderTypeComputed == 'dinein' && !tableMode">
            {{ $t('text.checkoutFinishedMessageBodyDinein') }}
            <br/>    
            {{ orderDateTime }}
            <br/>
            <br/>
            <div>
                {{ $t('text.pickupSpot') }}:
                <br/>
                {{ shop.name }}
                <br/>
                <span v-html="shop.custom6"></span>
            </div>
        </div>
        
        <div class="fontOrderSuccessMessage" v-if="tableMode && !immediate">
            {{ $t('text.checkoutFinishedMessageBodyTableMode') }}
            <br/>
        </div>

        <div class="fontOrderSuccessMessage" v-if="tableMode && immediate">
          {{ $t('text.checkoutFinishedMessageBodyTableMode') }}
          <br/>
          <b style="font-size: 42px">{{ orderPickupNumber }}</b>
          <br/>
          {{ $t('text.checkoutFinishedMessageBodyTableMode2') }}
        </div>

        <b-button type="button" v-if="!tableMode && !isGuestCheckout" @click="orderOverviewPage" class="btn btn-primary">{{$t('button.orderHistory')}}</b-button>
        <b-button type="button" v-if="!tableMode || immediate" @click="newOrder" class="btn btn-primary">{{$t('button.newOrder')}}</b-button>
    </div>
    <div v-else>
        <div class="fontTitle" style="text-align:center;">
            {{ $t('text.checkoutFinishedIFrameRedirecting') }}
        </div>
    </div>
</template>


<script>
// import FlowIndicator from '../main-components/FlowIndicator.vue'

export default {
    data(){
        return {
            comment:"",
            basketItems: {
                Type: Array
            },
            tableMode: false,
            shop: {
                Type: Object
            },
            immediate: false,
        }
    },
    components:{
    },
    beforeMount() {
        if(window.config.GUEST_CHECKOUT.enabled && window.isGuestCheckout == true)
        {
            this.logout();
        }

        this.tableMode = window.TableMode;
        this.immediate = window.immediate;
    },
    async mounted() {
      this.shop = window.shopObj;
      // Delete/Reset cookies
      if (window.config.COOKIES_ENABLED) {
        this.$cookies.remove('tableId');
        this.$cookies.remove('shopId');
        this.$cookies.remove('TableMode');
        // this.$cookies.remove('shopObj');
        this.$cookies.remove('orderType');
        this.$cookies.remove('immediate');
      }

      await this.doPost('TCPEmptyBasketJ.action', {
          json: "true"
      });
    },
    computed: {
        isGuestCheckout(){
            if(window.isGuestCheckout == true)
                return true;
            else
                return false;
        },
        orderTypeComputed() {
     
            return window.orderType;
        },
        orderDateTime (){
            return window.orderDateTime;
        },
        orderPickupNumber (){
          return window.orderPickupNumber;
        },
        isInIFrame(){
            try{
                return window.self !== window.top;
            } catch (e) {
                return true;
            }
        }
    },
    methods: {
      async logout() {
        var vueInstance = this;

        const result = await vueInstance.doGet('TCPLogoutJ.action', {});
        if (result.errorCode < 0) {
          vueInstance.makeToast('danger', result.errorMsg);
          return;
        }

        window.customerLoggedIn = null;
        window.isGuestCheckout = false;
      },
      orderOverviewPage() {
          this.$router.push({ path: '/OrderHistory' });
      },
      newOrder()
      {
          if (this.immediate && !window.config.TABLE_MODE_REFRESH_HOMEPAGE.isEmpty)
          {
              this.$router.push({ path: '/' + window.config.TABLE_MODE_REFRESH_HOMEPAGE });
          }
          else
          {
            this.$router.push({ path: '/Shop' });
          }
      }
    },
    beforeRouteLeave (to, from , next) {
        window.config.SHOW_GLOBAL_CONTROL_GROUP = !window.config.SHOW_GLOBAL_CONTROL_GROUP;
        // if(this.isInIFrame()) window.showHeaderFooter = true;
        next();
    },
    // eslint-disable-next-line no-unused-vars
    beforeRouteEnter (to, from, next) {
        window.config.SHOW_GLOBAL_CONTROL_GROUP = !window.config.SHOW_GLOBAL_CONTROL_GROUP;
        // if(this.isInIFrame()) window.showHeaderFooter = false;
         next();        
    },
}
</script>

<style scoped>
</style>
<template>
    <div class="loginPageWrapper">
            <loading :active.sync="isLoading" 
            :can-cancel="true"        
            :is-full-page="true"
            loader='bars'></loading>

            <div class="col-12 subPageHeader" style="position:relative;">
              <div class="row">
                <h1>{{ $t('title.login') }}</h1>

                <div class="ml-auto row" style="padding-right: 7px;">
                  <b-button class="btn btn-primary btn-justify btn-right" v-if="!config.FORCE_LOGIN_BEFORE_USE && !config.IS_PURE_ACCOUNTMANAGER" @click="goBack()">
                    {{$t('button.back')}}
                  </b-button>
                </div>
              </div>
            </div>

            <div class="innerScroll">
              <div class="col-12 loginBlock innerScroll">
                <div class="col-12 loginLeftBlock">
                    <span class="col-12 loginSubTitle"><h2>{{$t('subTitle.login')}}</h2></span>

                  <b-container fluid>
                    <b-form @submit.stop.prevent>
                      <b-form-row>
                        <b-col cols="12">
                          <b-form-group class="form-group fontField" label-for="emailAddr">
                            <template v-slot:label>
                              {{ $t('field.email') }}
                            </template>
                            <b-form-input class="form-control form-control-lg" id="emailAddr" name="emailAddr" type="email" v-model="$v.form.emailAddr.$model"
                                          :state="validateState('emailAddr')" aria-describedby="emailAddr-feedback" :maxLength="config.CUSTOMER_DATA.FIELDS.emailAddr.maxLength"/>
                            <b-form-invalid-feedback class="text-left" id="emailAddr-feedback">{{ $t('validation.invalidEmail') }}</b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                      </b-form-row>
                      <b-form-row>
                        <b-col cols="12">
                          <b-form-group class="form-group fontField" label-for="password">
                            <template v-slot:label>
                              {{ $t('field.password') }}
                            </template>
                            <b-input-group>
                              <b-form-input v-if="hidePassword" class="form-control form-control-lg" id="password" name="password" type="password" v-model="$v.form.password.$model"
                                            :state="validateState('password')"
                                            aria-describedby="password-feedback" />
                              <b-form-input v-else class="form-control form-control-lg" id="password" name="password" type="text" v-model="$v.form.password.$model"
                                            :state="validateState('password')"
                                            aria-describedby="password-feedback" />
                              <b-input-group-append>
                                <b-button v-if="config.SHOW_PASSWORD_VISIBILITY_SWITCH" class="btn-corner-right" variant="outline-secondary" type="button" @click="showPassword()" :aria-label="hidePassword ? $t('ariaLabel.showPasswordNew') : $t('ariaLabel.hidePasswordNew')">
                                  <b-icon-eye-fill v-if="hidePassword" aria-hidden="true" :aria-label="$t('ariaLabel.showPasswordNew')"></b-icon-eye-fill>
                                  <b-icon-eye-slash-fill v-else aria-hidden="true" :aria-label="$t('ariaLabel.hidePasswordNew')"></b-icon-eye-slash-fill>
                                </b-button>
                              </b-input-group-append>
                              <b-form-invalid-feedback class="text-left" id="password-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                      </b-form-row>
                      <b-form-row cols="12">
                        <b-button type="submit" @click.stop="onClickCaptcha" :disabled="submitStatus === 'PENDING'" class="btn btn-primary btn-lg btn-block btnSubmit g-recaptcha" style="width: 100%; margin-top: 43px;">{{$t('button.login')}}</b-button>
                        <b-button type="submit" @click.stop.prevent="loginSSO" v-if="SSOEnabled" :disabled="submitStatus === 'PENDING'" style="width: 100%" class="btn btn-dark btn-lg btn-block btnSubmit">{{ $t('button.customerSignInSSO') }}</b-button>
                        <b-link class="forgot-password-link" href="#" @click.prevent="onForgotPassword">{{ $t('link.forgotPassword') }}</b-link>
                      </b-form-row>
                    </b-form>
                  </b-container>
            </div>

            <div class="col-12 loginRightBlock">

              <span class="col-12 loginSubTitle" ><h2>{{ $t('subTitle.register') }}</h2></span>

              <b-container fluid>
                <b-form @submit.stop.prevent>
                  <b-form-row cols="12">
                    <div style="width: 100%" v-if="!tableMode" v-html="$t('text.registerInfo')"> </div>
                    <b-button class="btn btn-primary btn-lg btn-block btnSubmit g-recaptcha" type="submit" @click="goToRegister(false)" :disabled="submitStatus === 'PENDING'">{{$t('button.register')}}</b-button>
                    <b-button v-if="config.REGISTER_PARENT.enabled" class="btn btn-primary btn-lg btn-block btnSubmit g-recaptcha" type="submit" @click="goToRegisterAsParent(false)" :disabled="submitStatus === 'PENDING'">{{$t('button.registerAsParent')}}</b-button>
                    <b-button v-if="!isCartEmpty && config.GUEST_CHECKOUT.enabled" class="btn btn-primary btn-lg btn-block btnSubmit g-recaptcha" type="submit" @click="goToRegister(true)" :disabled="submitStatus === 'PENDING'">{{$t('button.registerGuest')}}</b-button>
                  </b-form-row>
                </b-form>
              </b-container>
           </div>
        </div>
            </div>

    </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import $ from 'jquery'
import { BIconEyeFill, BIconEyeSlashFill } from 'bootstrap-vue'
import Swal from 'sweetalert2'
import VueLoading from 'vue-loading-overlay'
import {UserTypes} from "@/constants/constants";
import Utils from "@/utils/utils";

export default {
    data() {
        return {
            submitStatus: null,
            alert: {
                message: "hello world"
            },
            form: {
                emailAddr: undefined,
                password: undefined,
            },
            formErrorMessage : null,
            siteKey: "",
            responseToken: "",
            hidePassword: true,
            rememberMe: false,
            isLoading: false,
            submit: false,
        }
	},
    computed: {
        config(){
            return window.config;
        },
        tableMode(){
            if(window.TableMode == true)
                return true;
            else
                return false;
        },
        SSOEnabled(){
            return this.config.SSO.enabled;
        },
        isCartEmpty()
        {
            if(window.TableMode == true)
            {
                return false;
            }

            if(window.shoppingCart !== undefined && window.shoppingCart !== null)
            {
                if(window.shoppingCart.length == 0)
                    return true;
                else
                    return false;
            }
            else
            {
                return true;
            }
        }
    },
    validations: {
       form: {
          emailAddr: {
              required,
              email
          },
         password: {
           required,
         },
      },
    },
    components: {
        'loading': VueLoading,
        BIconEyeFill,
        BIconEyeSlashFill,
    },
    methods: {
        validateState(name) {
          const {$dirty, $error} = this.$v.form[name];
          return $dirty ? !$error : null;
        },
        // eslint-disable-next-line no-undef
        onClickCaptcha(e) {
            var vueInstance = this;
            vueInstance.submit = true;
            console.log("onClickCaptcha");
            e.preventDefault();
            if(window.config.G_CAPTCHA.enabled == true)
            {
                // eslint-disable-next-line no-undef
                grecaptcha.ready(function() {
                    // eslint-disable-next-line no-undef
                    grecaptcha.execute(window.config.G_CAPTCHA.siteKey, {action: 'submit'}).then(function(token) {
                        vueInstance.responseToken = token;
                        vueInstance.onSubmit(e);
                    });
                });
            }
            else
            {
                vueInstance.onSubmit(e);
            }

        },
        showPassword() {
            this.hidePassword = !this.hidePassword;
            return;
        },
        handleLoginSuccess: function (result) {
            if(result.errorCode < 0)
            {
                return;
            }

            window.customerLoggedIn = result.loginUsername;
        },
        goBack(){
            this.$router.go(-2);
        },
        onForgotPassword() {
          this.$router.push({ path: '/ForgotPassword' })
        },
        goToRegister(isGuestCheckout) {
            if(isGuestCheckout)
            {
                window.isGuestCheckout = true;
                //avoid redundant navigation since its throws a exception in the console of the site
                if(!(this.$route.path == '/Guest'))
                {
                    this.$router.push({ path: '/Guest' })
                }
            }
            else
            {
                window.isGuestCheckout = false;
                //avoid redundant navigation since its throws a exception in the console of the site
                if(!(this.$route.path == '/Register'))
                {
                    this.$router.push({ path: '/Register' })
                }
            }
        },
        goToRegisterAsParent() {
          this.$router.push({ path: '/RegisterAsParent' })
        },
        loginSSO() {
            window.location.href = window.config.SSO.login_url;
        },
        onSubmit: async function (event) {
          var vueInstance = this;

          vueInstance.$v.$touch();
          if (vueInstance.$v.$invalid) {
            vueInstance.makeToast('danger', vueInstance.$t('notification.fillAllRequiredFields'));
            return;
          }

          this.formErrorMessage = null;
          var config = window.config;
          event.preventDefault();
          this.isLoading = true;

          const result = await vueInstance.doPost('TCPLoginSubmitJ.action', {
            json: "true",
            loginUsername: this.form.emailAddr,
            password: this.form.password,
            responseToken: this.responseToken != "" ? this.responseToken : undefined,
            rememberMe: this.rememberMe
          });

          vueInstance.isLoading = false;

          if (result.exception !== undefined) {
            vueInstance.makeToast('danger', vueInstance.$t('notification.loginError'));
            return;
          }

          if (result.page == "tcp.catalog.change.password.page") {
            await vueInstance.$router.push({path: '/PasswordReset'});
            return;
          }

          if (result.errorCode >= 0) {
            if (config.USE_ALLERGEN_FILTERS && result.wondCustomer.dietaryInfo != undefined) {
              window.dietaryInfo = result.wondCustomer.dietaryInfo;
            }

            vueInstance.handleLoginSuccess(result);

            if (UserTypes.PARENT === result.customer.custom3) {
              await vueInstance.$router.push({path: '/StudentAccounts'});
            }
            else if (window.shoppingCart != undefined && window.shoppingCart != null && window.shoppingCart.length > 0) {
              await vueInstance.$router.push({path: '/OrderConfirmation'});
            } else {
              vueInstance.$router.go({path: '/Shop'});
            }
          } else {
            if (vueInstance.config.USE_SPECIFIC_ERROR_MESSAGE_TPCLOGIN) {
              if (result.errorCode == -1 || result.errorCode == -2 || result.errorCode == -3 || result.errorCode == -4 || result.errorCode == -6) {

                vueInstance.formErrorMessage = result.errorMsg;

                const h = this.$createElement;
                const message = h('div', [
                  vueInstance.$t('notification.loginError'),
                  h('br'),
                  h('br'),
                  vueInstance.$t('notification.loginErrorSpecific'),
                ]);

                vueInstance.makeToast('danger', [message]);
              } else {
                vueInstance.formErrorMessage = result.errorMsg;
                vueInstance.makeToast('danger', vueInstance.$t('notification.loginError'));
              }
            } else {
              vueInstance.formErrorMessage = result.errorMsg;
              vueInstance.makeToast('danger', vueInstance.$t('notification.loginError'));
            }
          }

          this.shake = false;
        }
    },
    async created() {

      var scrollDiv = document.getElementById('contentSection');
      if (scrollDiv) {
        scrollDiv.style.overflowY = 'hidden';
      }

      let vueInstance = this;
      const result = await vueInstance.doGet('TCPLoginJ.action', {
        json: "true"
      });

      if (result.xsrfToken != undefined) {
        window.xtoken = atob(result.xsrfToken);
      }

      if (result.exception !== undefined) {
        vueInstance.makeToast('danger', vueInstance.$t('notification.loginError'));
        return;
      }

      if (result.page == "tcp.catalog.afterlogin.page") {
        window.customerLoggedIn = true;
        console.log(window.history.length);
        if (window.history.length > 2)
          vueInstance.$router.go(-1);
        return;
      }

      if (window.customerLoggedIn != undefined && window.customerLoggedIn != null) {
        await vueInstance.$router.push({path: '/Account'});
        return;
      }
    },
    beforeRouteLeave (to,from,next) {
        var scrollDiv = document.getElementById('contentSection');
        if (scrollDiv) {
          scrollDiv.style.overflowY = 'auto';
        }

        Swal.close();
        next();
    },
    mounted(){   
    //    $(".header").addClass("header-big");
    //         $(".header").addClass("animTransition");
    //         $(".content").addClass("header-big-content-margin");
    //         $(".content").removeClass("header-small-content-margin");
        $(".content").addClass("header-small-content-margin");
        // $(".click").on('click', goToForgotPasswort());
        this.siteKey = window.config.G_CAPTCHA.siteKey;
        const mainElement = document.getElementById('app');
        if (mainElement) {
          mainElement.focus();
        }

      Utils.adjustInnerScrollHeight();
      window.addEventListener('resize', Utils.adjustInnerScrollHeight);
    },

    beforeDestroy() {
      window.removeEventListener('resize', Utils.adjustInnerScrollHeight);
    },

     beforeRouteEnter (to, from, next) {
         // eslint-disable-next-line no-unused-vars
         next(vm => {
            var myDiv = document.getElementById('contentSection');
            $("#contentSection").scrollTop(0);
            $("#app").scrollTop(0);
            if(myDiv != null)
            myDiv.scrollTop = 0;
        })
    },
}
</script>

<style scoped>

input[type="password"]::-ms-reveal {
  display: none;
}

.linkColor{
    color:black;
}
    #content-wrapper{
        padding-top:0px;
        overflow-y: hidden;
    }

    .loginButtons
    {
        /* bottom:170px;
        position: absolute; */
        left: 0;
    }

    .loginButtonSize {
        width: 80%;
        /* float:right; */
        margin-left: auto;
        margin-right: auto;
    }

    .guestLoginButton
    {
        /* bottom:100px;
        position: absolute; */
        left: 0;
    }

    .loginBlock{
        display: inline-block;
        overflow: hidden;
    }

    .loginSubTitle {
        text-align: center;
        font-weight: bold;
        margin: auto;
    }

    .loginLeftBlock
    {
        width: 100%;
        float: left;
        padding-right: 10px;
        min-height: 400px;
        border-right:2px solid var(--main-color);
    }

    .loginRightBlock
    {
        width: 100%;
        min-height: 340px;
        padding-right: 10px;
    }

    .loginRightBlock p
    {
        padding: 30px 10% 30px 10%;
        display: inline-block;
        font-size: 18px;
    }

    @media screen and (max-width:770px) {

        .loginButtonSize {
            width: 100%;
            float:right;
            margin-left: 0;
            margin-right: 0;
        }

        .loginLeftBlock
        {
            border-right:none;
        }

        .loginRightBlock
        {
            min-height:300px;
        }
    }

     @media (min-width: 768px) {
        .loginRightBlock
        {
            width: 50%;
        }
         .loginLeftBlock
        {
            width: 50%;
        }
    }

    .loginButtonDiv
    {
      padding: 30px 10% 30px 10%;
    }

 .forgot-password-link {
      text-decoration: none;
      color: var(--main-color);
      font-weight: bold;
      margin-left: 6px;
    }
    .forgot-password-link:hover {
      color: var(--secondary-color);
    }

    .btn-corner-right{
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
    }

    @media only screen and (max-width: 768px) {
      .loginPageWrapper .innerScroll {
        height: calc(100vh - 220px) !important;
        width: 100% !important;
        overflow-x: visible !important;
        overflow-y: auto !important;
      }
    }
</style>
<template>
    <div class="addressSelectionWrapper" style="text-align:left;">

        <div class="col-12 subPageHeader" style="padding-left: 0px;" >
          <h1>{{ $t('subTitle.addressSelection') }}</h1>
        </div>  
        <div class="innerScroll">
          <flow :currentPoint="3"> </flow>

        <!-- <form @submit.prevent disabled class="checkoutPadding">
            
            <div class="col-12" style="padding:0">
                
                <b-button type="button" @click="editAddress" class="btn btn-primary floatRight" style="height: 48px; ">
                    <img class="editIcon" src="@/assets/images/editIcon.png" />   
                </b-button>
            </div>
            <div class="col-lg-6 col-md-12 col-xs-12">  
                <div class="form-group">
                    <label class="fontField">{{ $t('streetAddress')}}</label>
                    <input type="text" class="form-control form-control-lg" v-model="addresses[0].streetAddress" disabled/>     
                </div>

                <div class="form-group" >
                    <label class="fontField">{{ $t('suburb')}}</label>
                    <input type="text" class="form-control form-control-lg" v-model="addresses[0].suburb" disabled/>                 
                </div>

                <div class="form-group">
                    <label class="fontField">{{ $t('postalCode')}}</label>
                    <input type="text" class="form-control form-control-lg" v-model="addresses[0].postcode" disabled/>   
                </div>

            </div>
            <div class="col-lg-6 col-md-12 col-xs-12" style="padding-right:0px;">

           

                <div class="form-group">
                    <label class="fontField">{{ $t('city')}}</label>
                    <input type="text"  class="form-control form-control-lg" v-model="addresses[0].city" disabled/>    
                </div>

                <div class="form-group">
                    <label class="fontField">{{ $t('state')}}</label>
                    <input type="text" class="form-control form-control-lg" v-model="addresses[0].state" disabled/>
                </div>

                <div class="form-group">
                    <label class="fontField">{{ $t('telephoneNumber')}}</label>
                    <input type="text" class="form-control form-control-lg" v-model="addresses[0].telephoneNumber" disabled/>    
                </div>
            </div>        
            
            <div class="col-12">
                <b-button type="button" @click="onSubmit" class="btn btn-primary floatRight">{{$t('button.continue')}}</b-button>
            </div>       
         
        </form> -->

        <div class="col-12 addressFormPadding" v-show="showAddressForm">
             <select class="vueselect-wrapper" id="addressPicker" @change="closeAddressForm">
                 <option v-for="address in addresses" :key="address.id" :value="address.id" v-html="address.firstName + ' ' + address.lastName + ', ' + address.streetAddress + ', ' + address.postcode + ' ' +  address.city + ', ' + address.countryName +  ' - ' + (address.telephoneNumber ? address.telephoneNumber: '') "></option>
             </select>

             <b-button type="button" @click="editAddress" class="btn btn-primary btnAddress" >
                 <i class="editIcon fa fa-pencil">   </i>
             </b-button>

             <b-button type="button" @click="addNewAddress" class="btn btn-primary btnAddress">
                 <i class="editIcon fa fa-plus">   </i>
             </b-button>

         </div>

          <div id="addressFormWrapper" style="width:100%;">
              <div class="col-12 addressFormPadding"  v-if="showEditAddressForm">
                  <div class="col-9"><h2>{{$t('subTitle.editAddress')}}</h2></div>
                  <b-button type="button" v-if="selectedAddress.isPrimary == false" @click="deleteAddress" class="btn btn-inverted col-1" style="margin: 0px 0px 5px 0px;height: 48px;min-width:10%;">
                      <i class="editIcon fa fa-trash">   </i>
                   </b-button>
                  <address-form :addressId="selectedAddress.id" :addressModel="selectedAddress" :isEdit="true" :callbackClose="closeAddressForm" :customer="customer"></address-form>

              </div>

              <div class="col-12 addressFormPadding"  v-if="showNewAddressForm">
                  <h2>{{$t('subTitle.addAddress')}}</h2>
                  <address-form :addressId="0" :addressModel="addAddress" :isEdit="false" :callbackClose="closeAddressForm" :customer="customer"></address-form>
              </div>
          </div>

          <div class="col-12 checkoutPadding" v-if="showEditAddressForm == false && showNewAddressForm == false">
            <div class="forwardBackward">
              <b-button class="btn btn-primary" @click="goBack(false)" style="margin-left: 0" >{{$t('button.back')}}</b-button>
              <b-button type="button" @click="onSubmit" class="btn btn-primary" style="margin-right: 0">{{$t('button.continue')}}</b-button>
            </div>
          </div>
        </div>
    </div>
</template>


<script>
import { required } from 'vuelidate/lib/validators'
import FlowIndicator from '../main-components/FlowIndicator.vue'
import AddressForm from '../main-components/AddressForm.vue'
import $ from 'jquery'
import Swal from "sweetalert2";

export default {
    data(){
        return {
            comment:"",
            addresses: {
                Type: Array
            },
            customer: {
                Type: Object
            },
            currentAddress: "",
            showNewAddressForm: false,

            addAddress:{
                street: "",
                postalCode :"",
                city: ""
            },

            showEditAddressForm: false,
            showAddressForm: false,
            selectedAddress: {
                street: "",
                postalCode :"",
                city: ""
            }
        }
    },
    watch:{
        addresses: function () {
            if(window.orderType == "dinein")
            {

                setTimeout(() => {                        
                    this.onSubmit();
                    this.$router.push({ path: '/Payment' });
                }, 200);
              
            }
            
            // this.answer = 'Waiting for you to stop typing...'
            // this.debouncedGetAnswer()
        }
    },
    components:{
        'flow' : FlowIndicator,
        'address-form': AddressForm
    },
    validations: {
         addAddress: {
            street: {
                required 
            },
            postalCode: {
                required                   
            },
            city: {
                required
            }
        },        
    },

    methods:{
        checkForEmpty() {
            var vueInstance = this;
            var primary = vueInstance.addresses.filter(function(x) { return x.isPrimary == true})[0]
            if( primary == undefined || primary == null)
            {
                return;
            }

            if(primary.streetAddress.startsWith("---") && primary.city.startsWith("---"))
            {
                primary.streetAddress = "";
                primary.city = "";
                vueInstance.editAddress(primary.id);                
            }
        },
        setAddress(val){
            this.currentAddress = val;
        },       
        goBack(){
            this.$router.go(-1);
        },
        editAddress(addrId = 0) {
            this.showEditAddressForm = true;
            this.showNewAddressForm = false;
            var selectedAddressId = 0;
            if(addrId > 0)
            {
                selectedAddressId = addrId;
            }
            else
            {
                selectedAddressId = $('#addressPicker').val(); 
            }
             
            var selectedAddr = this.addresses.filter(function(x) { return x.id == selectedAddressId })[0];          
            this.selectedAddress = selectedAddr;

            /*
            setTimeout(() => {                             
                $(".content").animate({ scrollTop: 450 }, 300);
            }, 150);
             */

        } , 
        closeAddressForm(){
            $("#addressFormWrapper").animate({ height: 0 }, 900);
                  
            setTimeout(() => {                        
                this.showEditAddressForm = false;
                this.showNewAddressForm = false;
            }, 100);

            this.refreshAddresses();
        },
        async deleteAddress() {
          let vueInstance = this;

          await vueInstance.doGet('TCPDeleteAddrJ.action', {
            json: "true",
            addrId: vueInstance.selectedAddress.id
          });

          vueInstance.confirmDeleteAddress();
        },
        async confirmDeleteAddress() {
          let vueInstance = this;

          await vueInstance.doPost('TCPDeleteAddrSubmitJ.action', {
            json: "true",
            xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
          });

          vueInstance.makeToast('success', vueInstance.$t('notification.addressDeleted'));
          vueInstance.closeAddressForm();
        },
        addNewAddress(){
            this.showEditAddressForm = false;
            this.showNewAddressForm = true;

            $("#addressFormWrapper").animate({ height: 1000 }, 900);

            /*
            setTimeout(() => {                             
                $(".content").animate({ scrollTop: 450 }, 300);
            }, 150);
             */
        },
        async refreshAddresses() {
          let vueInstance = this;

          const result = await vueInstance.doGet('TCPAddrBookJ.action', {
            json: "true",
          });

          if (result.exception !== undefined) {
            vueInstance.$router.go(-1);
            return;
          }

          if (result.requiresLogin) {
            vueInstance.$router.push({path: '/Login'});
            return;
          }

          vueInstance.showAddressForm = true;
          vueInstance.addresses = result.addresses;

          if (window.orderType == "dinein") {
            return;
          } else if (window.orderType == "delivery") {
            vueInstance.checkForEmpty();
          }
        },
        async onSubmit() {
          let vueInstance = this;

          let selectedAddressId = $('#addressPicker').val();
          await vueInstance.doPost('TCPCheckoutChangeDeliveryAddrJ.action', {
            json: "true",
            addrId: selectedAddressId != "" ? selectedAddressId : this.addresses[0].id
          });

          await vueInstance.$router.push({path: '/Payment'});
        },       
    },
    created() {     
        this.refreshAddresses();
        var scrollDiv = document.getElementById('contentSection');
        if (scrollDiv) {
          scrollDiv.style.overflowY = 'hidden';
        }
    },
    async mounted() {
      let vueInstance = this;

      const result = await vueInstance.doGet('TCPAfterLoginJ.action', {
        json: "true",
      });

      vueInstance.customer = result.customer;
      window.isGuestCheckout = result.customer != undefined && result.customer.type != undefined && result.customer.type == 2 ? true : false;
    },
    beforeRouteLeave (to,from,next) {
      var scrollDiv = document.getElementById('contentSection');
      if (scrollDiv) {
        scrollDiv.style.overflowY = 'auto';
      }

      Swal.close();
      next();
    }
}
</script>

<style scoped>
.btnAddress {
    width:60px;
    margin: 0px 5px 5px 0px !important;
    height: 48px;
}

.editIcon {
    height:15px;
    width:15px;
}

#addressPicker{
    width:400px;   
    margin-right: 5px;
    margin-bottom: 5px;
    height:50px;
}

@media screen and (max-width:1000px) {
    #addressPicker{
        width:100%;
    }
}

@media screen and (max-width:768px){
    .btnAddress {
        width:50%;
    }
}

.forwardBackward {
  border-top: 1px solid var(--main-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

#content-wrapper
{
  overflow-y: hidden;
}


</style>